import React, { useRef, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
  Divider,
  IconButton
} from '@mui/material';

function FirstEventView(){
  return (
    <Box sx={{width:'100%', height:'100%', backgroundColor:'#222222', position:'relative'}}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{position:'absolute', bottom:'72px', width:'100%'}}
        spacing={2}>
        <img
          style={{
            width:'200px',
            objectFit:'contain',
            height:'64px'
          }}
          src="/assets/Google-Play-Icon.png" />
        <img
          style={{
            width:'200px',
            objectFit:'contain',
            height:'64px'
          }}
          src="/assets/App-Store-Icon.png" />
      </Stack>
    </Box>
  );
}

export default FirstEventView;
