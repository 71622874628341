import React, {useRef, useEffect, useState} from 'react';

import { SnackbarProvider, useSnackbar } from 'notistack';

export default function QueueLayout(props) {
  return (
    <SnackbarProvider maxSnack={3}>
      {props.children}
    </SnackbarProvider>
  );
}
