import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

import '../../css/carousel/CarouselView.css';

// import required modules
import {
  Scrollbar,
  Navigation
} from 'swiper/modules';

import {
  FirstEventView,
  SecondEventView,
  ThirdEventView
} from './Carousel';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function CarouselView (props) {

  return (
    <Swiper
        scrollbar={{
          hide: true,
        }}
        navigation={true}
        modules={[Scrollbar, Navigation]}
        className="mySwiper">
        <SwiperSlide>
          <FirstEventView />
        </SwiperSlide>
        <SwiperSlide>
          <SecondEventView />
        </SwiperSlide>
        <SwiperSlide>
          <ThirdEventView />
        </SwiperSlide>
      </Swiper>
  );
}

export default CarouselView;
