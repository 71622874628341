import React, { useRef, useState } from 'react';

import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
  Divider
} from '@mui/material';

function Footer(){

  const navigate = useNavigate();

  const footerNavigate = (url) => (event) => {
    navigate(url);
  };

  return (
    <Box sx={{backgroundColor:'black', pt:'48px', pb:'48px'}}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}>
              <Link href="/">
                <img src="/assets/icons/logo_transparent.png" style={{height:'24px', position:'relative', filter: 'brightness(0.8)'}}/>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}>
              <Typography sx={{color:'#cccccc', textAlign:'left', fontSize:'18px', p:'6px 0px', fontWeight:'900'}}>
                Products
              </Typography>
              <Link onClick={footerNavigate("/products/tumblerwasher")} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  텀블러 세척기
                </Typography>
              </Link>
              <Link onClick={footerNavigate("/products/cleaningcart")} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  청소 카트
                </Typography>
              </Link>
              <Typography sx={{color:'#cccccc', textAlign:'left', fontSize:'18px', p:'6px 0px', fontWeight:'900'}}>
                Services
              </Typography>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  Daclair
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  ENV-REPORT
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  ENV-AD
                </Typography>
              </Link>
              <Link href="/" sx={{textDecoration:'none'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  VCM
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}>
              <Typography sx={{color:'#cccccc', textAlign:'left', fontSize:'18px', pb:'12px', fontWeight:'900'}}>
                About us
              </Typography>
              <Link onClick={footerNavigate("/company/mission")} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  Mission
                </Typography>
              </Link>
              <Link onClick={footerNavigate("/company/sustainability")} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  Sustainability
                </Typography>
              </Link>
              <Link onClick={footerNavigate("/company/locations")} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  Locations
                </Typography>
              </Link>
              <Link onClick={footerNavigate("/company/contact")} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                  Contact US
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} sx={{mt:'24px'}}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem sx={{borderColor:'#777777'}}/>}>
                <Link onClick={footerNavigate("/notices")} sx={{textDecoration:'none', cursor:'pointer'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    공지사항
                  </Typography>
                </Link>
                <Link onClick={footerNavigate("/terms/usage")} sx={{textDecoration:'none', cursor:'pointer'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    이용약관
                  </Typography>
                </Link>
                <Link onClick={footerNavigate("/terms/privacy")} sx={{textDecoration:'none', cursor:'pointer'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    개인정보처리방침
                  </Typography>
                </Link>
                <Link onClick={footerNavigate("/terms/location")} sx={{textDecoration:'none', cursor:'pointer'}}>
                  <Typography sx={{color:'#aaa', textAlign:'left', fontSize:'12px', fontWeight:'900'}}>
                    위치정보 이용약관
                  </Typography>
                </Link>
              </Stack>
          </Grid>
          <Grid item container xs={12} md={12}>
            <Grid item md={2}>
              <Typography sx={{color:'#aaaaaa', textAlign:'left', fontSize:'12px'}}>
                ⓒ Daclair 2023
              </Typography>
            </Grid>
            <Grid item md>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography sx={{color:'#aaaaaa', textAlign:{xs:'left', md:'right'}, fontSize:'14px'}}>
                Powered by Varup Gigto
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer;
