import React from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,
  Divider
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import {
  Map,
  MapCameraChangedEvent,
  Marker
} from '@vis.gl/react-google-maps';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import moment from 'moment';
import 'moment/locale/ko';

const VARUP_LOCATION = { lat: 37.4831039, lng: 126.8935551 };
const GIGTO_LOCATION = { lat: 37.479502, lng: 126.955497 };
function LocationsPage(props){

  const navigate = useNavigate();

  const noticeBackAction = () => {
    navigate(-1);
  };

  return (
    <>
      <Container>
        <Box sx={{ my: 2, padding:'48px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            sx={{
              width:'100%',
              alignItems: "flex-start",
              justifyContent: "center",
            }}>
            <Grid item xs={12} sm={4} md={3}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'left',
                  fontSize:'14px',
                  mb:'6px',
                  color:blueGrey[800]
                }}>
                Hardware 사업부
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'18px',
                  mb:'18px',
                  color:blueGrey[800]
                }}>
                주식회사 바럽
              </Typography>

            </Grid>
            <Grid xs={12} sm={8} md={9} sx={{pl:{xs:'16px'}}}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'18px',
                  mt:'18px',
                  mb:'12px',
                  color:blueGrey[800]
                }}>
                서울특별시 구로구 구로동 디지털로 271 벽산디지털밸리 III 1011호
              </Typography>
              <Map
                style={{
                  width:'100%',
                  height:'280px',
                  mb:'24px',
                }}
                mapTypeControl={false}
                disableDefaultUI={true}
                defaultZoom={17}
                defaultCenter={VARUP_LOCATION}>
                <Marker position={VARUP_LOCATION} />
              </Map>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'18px',
                  color:blueGrey[800],
                  mt:'12px',
                  mb:'24px',
                }}>
                T. 070-8650-6246
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'left',
                  fontSize:'14px',
                  mb:'6px',
                  color:blueGrey[800]
                }}>
                플랫폼 및 Software 사업부
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'18px',
                  mb:'18px',
                  color:blueGrey[800]
                }}>
                주식회사 긱토
              </Typography>

            </Grid>
            <Grid xs={12} sm={8} md={9} sx={{pl:{xs:'16px'}}}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'18px',
                  mt:'18px',
                  mb:'12px',
                  color:blueGrey[800]
                }}>
                서울특별시 관악구 봉천로 518-4 5층
              </Typography>
              <Map
                style={{
                  width:'100%',
                  height:'280px',
                  mb:'24px',
                }}
                controlled={false}
                mapTypeControl={false}
                disableDefaultUI={true}
                defaultZoom={17}
                defaultCenter={GIGTO_LOCATION}>
                <Marker position={GIGTO_LOCATION} />
              </Map>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:400,
                  textAlign:'left',
                  fontSize:'18px',
                  color:blueGrey[800],
                  mt:'12px',
                  mb:'24px',
                }}>
                T. 02-6956-5002
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default LocationsPage;
