import React from 'react';
import Icon from '@mdi/react';
import { mdiAutoFix } from '@mdi/js';

export default function AutoFixIcon(props){
    return (
        <Icon path={ mdiAutoFix }
            title="auto fix icon"
            size={1}
            color={props.color ? props.color : "black"}/>
    );
};