import React from 'react';
import Icon from '@mdi/react';
import { mdiBasketOutline } from '@mdi/js';

export default function BasketOutlineIcon(props){
    return (
        <Icon path={mdiBasketOutline }
            title="basket outline icon"
            size={1}
            color={props.color ? props.color : "black"}/>
    );
};