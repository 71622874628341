import React from 'react';
import PropTypes from 'prop-types';

import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,
  Tabs,
  Tab,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';

import moment from 'moment';
import 'moment/locale/ko';

const columns = [
  { id: 'title', label: '내용', minWidth: 230, align: 'left' },
  { id: 'date', label: '등록일', minWidth: 100, align: 'left' },
];

function createData(title, date, id) {

  return { title, date, id };
}

const rows = [
  createData('첫 게시물 테스트', '24.09.01', 123),
];

function NoticeTable(props){

  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const labelDisplayedRows = ({ from, to, count }) => {
    return `전체 ${count} 게시물 중 ${from}–${to}`;
  };

  const noticeForward = (id) => (event) => {
    navigate(`/notices/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ position:'relative', width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ minHeight: 440 }}>
        <Table stickyHeader aria-label="notice table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: 900,
                    fontFamily: "NotoSansKR"
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                    onClick={noticeForward(row.id)}
                    style={{
                      cursor:'pointer'
                    }}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id} align={column.align}
                          style={{fontFamily: "NotoSansKR"}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={"힌 페이지당 게시물 수"}
        labelDisplayedRows={labelDisplayedRows}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default NoticeTable;
