import React, {useEffect, useState, useRef} from 'react';

export default function SplashLayout(){
  return (
    <>
    <div id="splash">
    </div>
    </>
  )
}
