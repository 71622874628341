import React, { useRef, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
  Divider,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

function EventBanner(){
  return (
    <Box sx={{backgroundColor:'black', width:'100%', height:'60px', position:'relative'}}>
      <Box sx={{width:'100%', height:'100%'}}>
        <Stack
          sx={{width:'100%', height:'100%', pl:'12px'}}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}>
          <IconButton aria-label="fingerprint" sx={{color:'#ffffff'}}>
            <CloseIcon color="white"/>
          </IconButton>
          <Typography
            sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>
            첫 런칭 기념 기기 및 부속 용품 10% 할인
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}

export default EventBanner;
