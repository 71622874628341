import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,

} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

function FirstEnviromentContainer(props){
  return (
    <Box
      sx={{width:'100%', height:'50vh', position:'relative'}}>
      <Box sx={{position:'relative', width:'100%', height:'100%'}}>
        <img
          style={{
            position:'absolute',
            top:'0px', bottom:'0px', left:'0px', right:'0px', width:'100%', height:'100%',
            objectFit:'cover', filter:'brightness(40%)'
          }}
          src="/assets/images/env_back1.jpg" />
        <Box sx={{
          position:'absolute',
          top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
          background:'linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1))'
        }}>
        <Container sx={{position:'relative', width:'100%' , height:'100%'}}>
          <Grid
            container
            direction="row"
            sx={{
              position:'relative', width:'100%', height:'100%',
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid item xs={0} sm={0} md={6}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:700,
                    fontSize:'16px',
                    textAlign:'left',
                    width:'100%',
                    color:lightBlue[300]
                  }}>
                  자원 절약
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'28px',
                    textAlign:'left',
                    width:'100%',
                    color:blueGrey[100]
                  }}>
                  다끌레어는 얼마나 절약할까요?
                </Typography>
                <Box sx={{width:'100%', height:'24px'}}></Box>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'18px',
                    textAlign:'left',
                    width:'100%',
                    color:'#eeeeee'
                  }}>
                  다끌레어 텀블러 세척기는 최적의 물 사용량과, 수온,
                  <br/> 수압을 통해 일반 텀블러를 세척하는 방식에 대비하여
                  <br/> 물과 전기를 절약할 수 있습니다.
                </Typography>
                <Box sx={{width:'100%', height:'24px'}}></Box>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'18px',
                    textAlign:'left',
                    width:'100%',
                    color:'#eeeeee'
                  }}>
                  <Button variant="outlined" sx={{border:'1px solid #ffffff', color:'#ffffff'}}
                    endIcon={<ArrowForwardTwoToneIcon sx={{color:'#ffffff'}}/>}>
                  절약량 미리보기
                  </Button>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default FirstEnviromentContainer;
