import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import {
  Box,
  Container,
  Typography,
  Divider
} from '@mui/material';

import {
  TextTitle,
  TextSubtitle1,
  TextParagraph
} from '../../components/terms';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
} from '@mui/material/colors';

function UsagePage(props){
  return (
    <Container sx={{width:'100%', position:'relative'}}>
      <Box sx={{ my:2, padding:'48px 24px 108px'}}>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:900,
            textAlign:'left',
            fontSize:'32px',
            mb:'24px',
            color:blueGrey[900]
          }}>
          이용약관
        </Typography>
        <Divider sx={{mb:'24px'}}/>
        <TextTitle>제 1 조 (목적)</TextTitle>
        <TextParagraph>
        이 약관은 ㈜긱토(이하 '회사')가 제공하는 회사 및 회사 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.  본 약관은 PC 통신, 스마트폰(안드로이드폰, 아이폰 등) 앱 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 준용됩니다.
        </TextParagraph>
        <TextTitle>제 2 조 (용어의 정의)</TextTitle>
        <TextSubtitle1>
        1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        </TextSubtitle1>
        <TextParagraph>
        (1) '서비스'라 함은 접속 가능한 유.무선 단말기의 종류와는 상관없이 이용 가능한 '회사'가 제공하는 모든 '서비스'를 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (2) '이용자'라 함은 회사 서비스에 접속하여, 이 약관에 따라 회사가 제공하는 서비스를 이용하는 회원과 비회원을 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (3) '회원'라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용 계약을 체결하고 회사가 제공하는 서비스를 이용하는 개인 또는 기업을 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (4) 'ID'라 함은 회원의 식별과 이용을 위하여 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (5) '비밀번호'라 함은 회원이 부여 받은 아이디와 일치하는 회원임을 확인하고 회원의 비밀보호를 위해 회원 자신이 설정한 문자와 숫자의 조합을 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (6) '탈퇴'라 함은 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것을 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (7) '콘텐츠'이라 함은 회사, 회원 또는 콘텐츠제공자가 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보 형태의 글, 사진, 동영상, 음원 및 각종 파일과 링크 등을 편집하여 회사가 운영하는 서비스에 업로드 할 목적으로 제작되는 창작물을 의미합니다.
        </TextParagraph>
        <TextParagraph>
        (8) ‘콘텐츠제공자’라 함은 친환경 관련 서비스업체 등 회사와 고용계약, 업무위탁계약 또는 업무제휴계약 등을 체결하여 서비스를 제공 및 업로드 할 목적으로 콘텐츠를 공급하는 개인 또는 기업을 의미합니다.
        </TextParagraph>
        <TextSubtitle1>
        2.이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스 별 안내에서 정하는 바에 의합니다.
        </TextSubtitle1>
        <TextTitle>제 3 조 (이용약관의 효력 및 변경)</TextTitle>
        <TextParagraph>
        1.이 약관은 (주)긱토(이하 '회사')가 제공하는 온라인상의 인터넷 서비스(이하 '서비스')의 이용약관으로 이용자에게 공시함으로써 효력이 발생합니다.
        </TextParagraph>
        <TextParagraph>
        2.회사는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 『전자문서 및 전자거래기본법』, 『전자금융거래법』, 『전자서명법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『소비자기본법』 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다
        </TextParagraph>
        <TextParagraph>
        3.개정된 약관에 이용자의 권리 또는 의무 등 중요한 규정의 개정이 있는 경우 사전에 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
        </TextParagraph>
        <TextParagraph>
        4.회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
        </TextParagraph>
        <TextParagraph>
        5.이 약관에 동의하는 것은 정기적으로 웹(https://daclair.com)을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
        </TextParagraph>
        <TextParagraph>
        6.회원이 변경된 약관에 동의하지 않을 경우 회사는 해당 회원의 탈퇴(해지)를 요청할 수 있습니다.
        </TextParagraph>

        <TextTitle>제 4 조 (약관 외 사항에 대한 준칙)</TextTitle>
        <TextTitle>제 5 조 (서비스 이용계약 체결)</TextTitle>
        <TextTitle>제 6 조 (이용 신청의 승낙과 제한)</TextTitle>
        <TextTitle>제 7 조 (개인정보 보호)</TextTitle>
        <TextTitle>제 8 조 (회원ID 부여 및 변경 등)</TextTitle>
        <TextTitle>제 9 조 (회사의 의무)</TextTitle>
        <TextTitle>제 10 조 (회원의 의무)</TextTitle>
        <TextTitle>제 11 조 (서비스의 내용 및 종류)</TextTitle>
        <TextTitle>제 12 조 (게시물의 관리)</TextTitle>
        <TextTitle>제 13 조 (권리의 귀속)</TextTitle>
        <TextTitle>제 14 조 (콘텐츠의 사용)</TextTitle>
        <TextTitle>제 15 조 (계약 변경 및 해지)</TextTitle>
        <TextTitle>제 16 조 (서비스 이용제한)</TextTitle>
        <TextTitle>제 17 조 (손해배상)</TextTitle>
        <TextTitle>제 18 조 (면책조항)</TextTitle>
        <TextTitle>제 19조 (재판권 및 준거법)</TextTitle>
        <TextTitle>제 20 조 (정보의 제공 및 광고의 게재)</TextTitle>
        <Divider sx={{mb:'24px'}}/>
        <TextTitle>부칙 제 1조</TextTitle>
      </Box>
    </Container>
  )
};

export default UsagePage;
