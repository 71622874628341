import React, {useRef, useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Collapse from '@mui/material/Collapse';

import { pink, blue, deepOrange } from '@mui/material/colors';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AdbIcon from '@mui/icons-material/Adb';

import { SnackbarProvider, useSnackbar } from 'notistack';

import css_main from '../../css/main.module.css';

import {
  MainNavItems
} from '../../resources';

const drawerWidth = 240;
const settings = [
  {
    name : '프로필 관리',
    key : "my_setting_profile"
  },
  {
    name : '대시보드',
    key : "my_setting_dashboard"
  },
  {
    name : '로그아웃',
    key : "my_setting_logout"
  }
];


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    //marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function SignedLayoutInner(props) {
  const { window } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [accordionOpen, setAccordionOpen] = React.useState({});

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [appBarHeight, setAppBarHeight] = useState(0);

  const [activeElement, setActiveElement] = React.useState({});
  const [activeSubElement, setActiveSubElement] = React.useState({});

  const appbarTop = useRef(null);

  useEffect(() => {
    setAppBarHeight(appbarTop.current.clientHeight);
  });

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => (event) => {
    setAnchorElUser(null);
    switch(setting){
      case "my_setting_profile" :
        navigate('/auth/account');
        break;
      case "my_setting_logout" :
        props.removeToken();
        navigate('/');
        break;
      default : break;
    }

  };

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleAccordionClick = (prop) => (event) => {
    setMobileOpen(true);
    setActiveElement( (prev) => (prop) );
    setAccordionOpen({ ...accordionOpen, [prop.key]: !accordionOpen[prop.key] });
  };

  const handleLinkClick = (type, prop) => (event) => {
    // when page moved, navigation should be closed
    
    if(type === "MAIN"){
      setActiveElement( (prev) => (prop) );
      setMobileOpen(false);
    }else{
      setActiveSubElement( (prev) => (prop) );
      setMobileOpen(true);
    }
  
    navigate(prop, {replace: true});
  }

  const myMenu = (
    <Box sx={{ flexGrow: 0 }}>
       <Tooltip title="나의 설정">
         <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
           <Avatar
            sx={{ width: 32, height: 32, bgcolor: deepOrange[500] }}
            variant="square">
            HI
           </Avatar>
         </IconButton>
       </Tooltip>
       <Menu
         sx={{ mt: '45px', zIndex:1200 }}
         id="menu-appbar"
         anchorEl={anchorElUser}
         anchorOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         keepMounted
         transformOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         open={Boolean(anchorElUser)}
         onClose={handleCloseUserMenu("")}
       >
         {settings.map((setting) => (
           <MenuItem key={`top-menu-button-${setting.key}`} onClick={handleCloseUserMenu(setting.key)}>
             <Typography textAlign="center">{setting.name}</Typography>
           </MenuItem>
         ))}
       </Menu>
     </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', height:"100vh" }}>
      <AppBar 
        ref={appbarTop} 
        position="fixed" 
        elevation={0}
        sx={{backgroundColor:'#ffffff'}}
        open={mobileOpen}>
        <Toolbar>
          {/*<IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(mobileOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>*/}
          <Typography variant="h6" noWrap component="div">
          <img
              src="/assets/icons/yoon_studio_horizontal_logo.png"/>
          </Typography>
          <Box sx={{flexGrow:1}}></Box>
          {myMenu}
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={mobileOpen}>
        {/*<DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          </DrawerHeader>*/}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}>
          <Box sx={{position:'relative', width:'64px', height:'100vh', backgroundColor:'#173E83'}}>
            <DrawerHeader></DrawerHeader>
            {MainNavItems.map((item) => (
              (item.type == 'accordion') ?
              <React.Fragment  key={`nav-drawer-fragment-${item.key}`} >
                <ListItemButton sx={{height:'64px', backgroundColor:((item.key == activeElement.key) ? '#E0C7C2 !important' : '#173E83') }} key={item.key} onClick={handleAccordionClick(item)}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
                {/*<Collapse in={accordionOpen[item.key]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {(item.children.length > 0) && item.children.map((_child) => (
                  <ListItemButton
                    key={_child.key}
                    onClick={handleLinkClick(_child.to)}
                    sx={{ pl: '72px' }}>
                    <ListItemText primary={_child.title} />
                  </ListItemButton>
                ))}
                </List>
                </Collapse>*/}
              </React.Fragment> :
              <ListItem key={item.key} sx={{backgroundColor:((item.key == activeElement.key) ? '#E0C7C2 !important' : '#173E83')}} disablePadding>
                <ListItemButton
                  onClick={handleLinkClick("MAIN", item)}
                  sx={{ textAlign: 'left', height: '64px' }}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <Box sx={{position:'relative', width:`calc(100% - 64px)`, height:'100vh', backgroundColor:'#E0C7C2'}}>
            <DrawerHeader></DrawerHeader>
            <List>
              {activeElement && activeElement.children && activeElement.children.length > 0 && (
                <ListItem key={activeElement.key} disablePadding sx={{pl:'18px', pt:'18px', pb:'18px' }}>
                  <ListItemText disableTypography={true}>
                    <Typography
                      sx={{fontSize:'20px', fontWeight:900}}>
                      {activeElement.title}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {activeElement.children && activeElement.children.length > 0 && activeElement.children.map((item) => (
                <ListItem key={item.key} disablePadding>
                  <ListItemButton
                    onClick={handleLinkClick("END", item)}
                    sx={{ textAlign: 'left', pl:'32px' }}>
                    <ListItemText disableTypography={true}>
                      <Typography
                        sx={{fontSize:'16px', fontWeight:(item.key === activeSubElement.key ? 900 : 500), color:(item.key === activeSubElement.key ? '#173E83' : '#000000')}}>
                        {item.title}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
              </ListItem>
              ))}
            </List>
          </Box>
        </Stack>
        {/*<List>
          {MainNavItems.map((item) => (
            (item.type == 'accordion') ?
            <React.Fragment  key={`nav-drawer-fragment-${item.key}`} >
              <ListItemButton key={item.key} onClick={handleAccordionClick(item.key)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.title} />
                {accordionOpen[item.key] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={accordionOpen[item.key]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              {(item.children.length > 0) && item.children.map((_child) => (
                <ListItemButton
                  key={_child.key}
                  onClick={handleLinkClick(_child.to)}
                  sx={{ pl: '72px' }}>
                  <ListItemText primary={_child.title} />
                </ListItemButton>
              ))}
              </List>
              </Collapse>
            </React.Fragment> :
            <ListItem key={item.key} disablePadding>
              <ListItemButton
                onClick={handleLinkClick(item.to)}
                sx={{ textAlign: 'left' }}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
              </List>*/}
      </Drawer>
      <Box component="main" sx={{ p: 3, flexGrow: 1, overflow:"hidden" }}>
        <DrawerHeader />
        <Box component="div" sx={{position:'relative', width:"100%", p:0, height:`calc(100vh - ${appBarHeight}px)`}}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

SignedLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function SignedLayout(props) {
  return (
    <SignedLayoutInner {...props}>
      {props.children}
    </SignedLayoutInner>
  );
}
