import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';


import {
  MainPage,
  CleaningCartPage,
  TumblerWasherPage,
  FindPasswordPage,

  NoticeListPage,
  NoticeContentPage,

  UsageTermsPage,
  PrivacyTermsPage,
  LocationTermsPage,

  MissionCompanyPage,
  SustainabilityCompanyPage,
  LocationsCompanyPage,
  ContactUsCompanyPage

} from '../containers'

import Footer from '../components/common/Footer';
import Header from '../components/common/Header';

export default function NonSignedRoute(props){

  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <Header {...props} />
      <Routes>
        <Route path='/' element={<MainPage setToken={props.setToken} />} />
        <Route path='/products/tumblerwasher' element={<TumblerWasherPage setToken={props.setToken} />} />
        <Route path='/products/cleaningcart' element={<CleaningCartPage setToken={props.setToken} />} />
        <Route path='/contents/:id' element={<MainPage setToken={props.setToken} />} />
        <Route path='/contents' element={<MainPage setToken={props.setToken} />} />
        <Route path='/services/:name' element={<MainPage setToken={props.setToken} />} />
        <Route path='/notices' element={<NoticeListPage setToken={props.setToken} />} />
        <Route path='/notices/:id' element={<NoticeContentPage setToken={props.setToken} />} />
        <Route path='/docs' element={<MainPage setToken={props.setToken} />} />
        <Route path='/terms/usage' element={<UsageTermsPage setToken={props.setToken} />} />
        <Route path='/terms/privacy' element={<PrivacyTermsPage setToken={props.setToken} />} />
        <Route path='/terms/location' element={<LocationTermsPage setToken={props.setToken} />} />

        <Route path='/company/mission' element={<MissionCompanyPage setToken={props.setToken} />} />
        <Route path='/company/sustainability' element={<SustainabilityCompanyPage setToken={props.setToken} />} />
        <Route path='/company/locations' element={<LocationsCompanyPage setToken={props.setToken} />} />
        <Route path='/company/contact' element={<ContactUsCompanyPage setToken={props.setToken} />} />

        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}
