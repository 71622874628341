import React from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,
  Divider
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import moment from 'moment';
import 'moment/locale/ko';

function SustainabilityPage(props){

  const navigate = useNavigate();

  const noticeBackAction = () => {
    navigate(-1);
  };

  return (
    <>
      <Box sx={{position:'relative', width:'100%'}}>
        <Container
          sx={{position:'relative', width:'100%', py:'18px', height:'calc(100vh - 84px)'}}>
          <Box sx={{ my: 2, padding:'48px 0px 108px', position:'relative', height:'100%' }}>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                position: 'relative',
                width: '100%',
                height: '100%'
              }}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'32px',
                  color:blueGrey[800]
                }}>
                지속 가능한 미래를 위한 우리의 약속
                <br/>지구와 함께하는 지속 가능성
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'20px',
                  color:blueGrey[500]
                }}>
                다끌레어는 지구와 사람을 위한 지속 가능한 솔루션을 제공합니다. 우리는 친환경 원료 사용, 에너지 절약, 그리고 사회적 책임을 통해 보다 나은 세상을 만들기 위해 노력합니다.
              </Typography>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box sx={{ my: 2, padding:'108px 0px 108px', backgroundColor:blueGrey[700] }}>
        <Stack spacing={2}
          direction="column"
          justifyContent="center"
          sx={{ width:'100%'}}
          alignItems="center">

          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:900,
              textAlign:'center',
              fontSize:'32px',
              mb:'48px !important',
              color:'#ffffff'
            }}>
            다끌레어에 대해 더 많은 정보를 알고 싶다면
          </Typography>


          <Stack
            direction="column"
            className="da-button-arrow"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{padding:'0px 0px 0px 0px', width:'100%', maxWidth:'300px', boxSizing:'border-box'}}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'22px',
                textAlign:'left',
                color:'#ddd'
              }}>
              우리의 미션에 동참하세요
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'16px',
                textAlign:'left',
                color:'#ddd'
              }}>
              뉴스레터 가입하기
            </Typography>
          </Stack>

        </Stack>
      </Box>
    </>
  );
}

export default SustainabilityPage;
