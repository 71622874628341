export const VERSION = "VERSION";

export const versionChange = (version) => ({ type: VERSION, version });

const initalState = {
  version : "V1"
};

export default function versionControl (state = initalState, action) {
  switch (action.type) {
    case VERSION:
      return {
        ...state,
        version: action.version
      };

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};
