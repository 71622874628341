import React from "react";

const SectionView = ({ children }) => {
  return (
    <section
      style={{
        margin: "0px"
      }}
    >
      {children}
    </section>
  );
};

export default SectionView;
