import React from 'react';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

function CleaningCartPage(props){
  return (
    <>
      <Box sx={{position:'relative', width:'100%', paddingBottom:'60%', maxHeight:'100vh', backgroundColor:'#222222'}}>
      </Box>
      <Container>
        <Box sx={{ my: 2, padding:'48px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'42px',
                  mb:'48px',
                  color:blueGrey[800]
                }}>
                사람을 먼저 생각하는 청소카트
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  fontSize:'16px',
                  textAlign:'left',
                  color:blue[500]
                }}>
                초경량 & 튼튼한 카트
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  fontSize:'28px',
                  textAlign:'left',
                  mb:'24px',
                  color:blueGrey[900]
                }}>
                사람을 위한 카트
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                  fontSize:'18px',
                  textAlign:'left',
                  lineHeight:1.8,
                  color:blueGrey[900]
                }}>
                아직도 불편한 청소카트로 고생하고 계신가요 ?
                <br/>Daclair 카트는 초경량, 튼튼한 청소카트입니다
                <br/>더 이상 무거운 카트를 끌고다니지 마세요
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%'}}>
                <img
                  style={{
                    position:'absolute',
                    width:'100%', height:'100%',
                    top:'0px', left:'0px', right:'0px', bottom:'0px',
                    objectFit:'contain'
                  }}
                  src="/assets/images/cart_image1.png" />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'100%'}}>
                <img
                  style={{
                    position:'absolute',
                    width:'100%', height:'100%',
                    top:'0px', left:'0px', right:'0px', bottom:'0px',
                    objectFit:'contain'
                  }}
                  src="/assets/images/cart_image3.png" />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  fontSize:'16px',
                  textAlign:'left',
                  color:blue[500]
                }}>
                혁신 장터 및 공공기관에 검증되었습니다.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  fontSize:'28px',
                  textAlign:'left',
                  mb:'24px',
                  color:blueGrey[900]
                }}>
                다양한 곳에서 검증받은 카트
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                  fontSize:'18px',
                  textAlign:'left',
                  lineHeight:1.8,
                  color:blueGrey[900]
                }}>
                혁신장터 등록 및 KTX / SRT 등에 검증되었습니다.
                <br/>Daclair 카트는 29여곳에 235세트가 납품되었습니다.
                <br/>기관을 위해 커스텀도 가능합니다
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2, padding:'48px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'42px',
                  mb:'12px',
                  color:blueGrey[800]
                }}>
                다양한 종류의 청소카트
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                  textAlign:'center',
                  fontSize:'26px',
                  mb:'48px',
                  color:blueGrey[500]
                }}>
                상황과 환경에 따라 청소카트를 선택할 수 있습니다
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>

                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    소형
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    40 X 60 X 70 (cm)
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>

                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    웨건형
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    90 X 50 X 85 (cm)
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>

                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    혼합형
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    90 X 50 X 85 (cm)
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        sx={{width:'100%', backgroundColor:blueGrey[800]}}>
        <Box sx={{ my: 2, padding:'72px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            spacing={2}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'36px',
                  mb:'72px',
                  color:'#ffffff'
                }}>
                처음 사용하시거나, 사용 방법을 잘 모르시나요?
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Stack spacing={2}
                direction="column"
                justifyContent="center"
                sx={{ width:'100%'}}
                alignItems="center">
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{padding:'0px 0px 0px 0px', width:'100%', maxWidth:'300px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    사용 메뉴얼을 확인해보세요
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    Daclair 사용 메뉴얼
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack spacing={2}
                direction="column"
                justifyContent="center"
                sx={{ width:'100%'}}
                alignItems="center">
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{padding:'0px 0px 0px 0px', width:'100%', maxWidth:'300px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    고객센터에 문의하세요
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    문의하기
                  </Typography>
                </Stack>
              </Stack>
            </Grid>


          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CleaningCartPage;
