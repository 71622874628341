import React, {useEffect, useState} from 'react';

import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import {
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Drawer,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import EventBanner from '../../components/banner/EventBanner';
import NavigationList from './NavigationList';

import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import Face2OutlinedIcon from '@mui/icons-material/Face2Outlined';
import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone';

function EffectScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const trigger_elevation = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  const _children = React.cloneElement(children, {
    elevation: trigger_elevation ? 4 : 0,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {_children}
    </Slide>
  );
}

EffectScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Header(props) {

  const navigate = useNavigate();

  const [navOpen, setNavOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setNavOpen(newOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl2(null);
  };
  const handleClose = (type) => () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    if(type !== null){
      switch(type){
        case "DACLAIR" : {
          navigate("/services/daclair");
          break;
        }
        case "ENV-REPORT" : {
          navigate("/services/env-report");
          break;
        }
        case "ENV-AD" : {
          navigate("/services/env-ad");
          break;
        }
        default : {
          break;
        }
      }
    }
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    setAnchorEl(null);
  };
  const handleClose2 = (type) => () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    if(type !== null){
      switch(type){
        case "CART" : {
          navigate("/products/cleaningcart");
          break;
        }
        case "TUMBLER" : {
          navigate("/products/tumblerwasher");
          break;
        }
        default : {
          break;
        }
      }
    }
  };

    return (
    <>
      <CssBaseline />
      <EffectScroll {...props}>
        <AppBar sx={{backgroundColor:'black', height:'auto'}}>
          <EventBanner />
          <Toolbar sx={{position:'relative', height:'100%'}}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{height:'100%', width:'100%'}}
              spacing={2}>
              <Typography variant="h6" component="div">
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}>
                  <IconButton
                    onClick={toggleDrawer(true)}
                    sx={{display:{xs:'flex', md:'none'}}}>
                    <AppsTwoToneIcon sx={{color:'#ffffff'}}/>
                  </IconButton>
                  <Link href="/" sx={{height:'24px'}}>
                    <img src="/assets/icons/logo_transparent.png" style={{height:'24px', position:'relative'}}/>
                  </Link>
                </Stack>
              </Typography>
              <Box sx={{flexGrow:1}}>
                <Stack
                  sx={{display:{xs:'none', md:'flex'}}}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                  <Button
                    onClick={handleClick}
                    onMouseOver={handleClick}
                    variant="text"
                    sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>서비스 <ArrowDropDownTwoToneIcon/> </Button>
                  <Button variant="text" sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}
                    onClick={handleClick2}
                    onMouseOver={handleClick2}>제품 <ArrowDropDownTwoToneIcon /> </Button>
                  <Button variant="text" sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>마켓 플레이스</Button>
                  <Button variant="text" sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>고객지원</Button>
                  <Button variant="text" sx={{color:'#ffffff', fontFamily: "NotoSansKR"}}>탄소중립마켓</Button>
                </Stack>
              </Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}>
                <IconButton sx={{color:'#ffffff'}}>
                  <SearchOutlinedIcon />
                </IconButton>
                <IconButton sx={{color:'#ffffff'}}>
                  <ScienceOutlinedIcon />
                </IconButton>
                <IconButton sx={{color:'#ffffff'}}>
                  <Face2OutlinedIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </EffectScroll>
      <Box sx={{height:'auto', backgroundColor:'black'}}>
        <Box sx={{width:'100%', height:'60px'}} />
        <Toolbar sx={{position:'relative', height:'100%'}}>
        </Toolbar>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose(null)}
        sx={
          { mt: "1px", "& .MuiMenu-paper":
            { backgroundColor: blueGrey[900], color:'#ffffff '},
          }
        }
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          onMouseLeave: handleClose(null)
        }}
      >
        <MenuItem
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:500,
            fontSize:'14px',
          }}
          onClick={handleClose("DACLAIR")}>다끌레어</MenuItem>
        <MenuItem
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:500,
            fontSize:'14px',
          }}
          onClick={handleClose("ENV-REPORT")}>ENV-REPORT</MenuItem>
        <MenuItem
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:500,
            fontSize:'14px',
          }}
          onClick={handleClose("ENV-AD")}>ENV-AD</MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2(null)}
        sx={
          { mt: "1px", "& .MuiMenu-paper":
            { backgroundColor: blueGrey[900], color:'#ffffff '},
          }
        }
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          onMouseLeave: handleClose2(null)
        }}
      >
        <MenuItem
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:500,
            fontSize:'14px',
          }}
          onClick={handleClose2("CART")}>청소 카트</MenuItem>
        <MenuItem
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:500,
            fontSize:'14px',
          }}
          onClick={handleClose2("TUMBLER")}>텀블러 세척기</MenuItem>
      </Menu>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: blueGrey[900],
            color: "#ffffff",
          }
        }}
        open={navOpen} onClose={toggleDrawer(false)}>
        <NavigationList
          onToggleDrawer={toggleDrawer}/>
      </Drawer>
    </>
    );
}

export default Header;
