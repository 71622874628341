import React from 'react';
import Icon from '@mdi/react';
import { mdiCurrencyUsd } from '@mdi/js';

export default function CurrencyUsdIcon(props){
    return (
        <Icon path={ mdiCurrencyUsd }
            title="currency usd icon"
            size={1}
            color={props.color ? props.color : "black"}/>
    );
};