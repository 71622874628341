import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import {
  Box,
  Container,
  Typography,
  Divider
} from '@mui/material';

import {
  TextTitle,
  TextSubtitle1,
  TextParagraph
} from '../../components/terms';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
} from '@mui/material/colors';


function LocationPage(props){
  return (
    <Container sx={{width:'100%', position:'relative'}}>
      <Box sx={{ my:2, padding:'48px 24px 108px'}}>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:900,
            textAlign:'left',
            fontSize:'32px',
            mb:'24px',
            color:blueGrey[900]
          }}>
          위치서비스 이용약관
        </Typography>
        <Divider sx={{mb:'24px'}}/>
        <TextSubtitle1>
        Daclair는 위치정보의 보호 및 이용 등에 관한 법률에 따라 이용자의 위치정보 보호 및 권익을 보호하고 위치정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다. Daclair는 위치정보 수집 동의 약관을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
        </TextSubtitle1>
        <TextTitle>제 1 조 (목적)</TextTitle>
        <TextParagraph>
        이 약관은 주식회사 긱토 (이하 “회사”)가 제공하는 위치정보사업 또는 위치기반서비스사업과 관련하여 회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        </TextParagraph>
        <TextTitle>제 2 조 (약관 외 준칙)</TextTitle>
        <TextParagraph>
        이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법, 전기통신사업법 등 관계법령과 회사의 이용약관 및 개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
        </TextParagraph>
        <TextTitle>제 3 조 (서비스 내용 및 요금)</TextTitle>
        <TextTitle>제 4 조 (개인위치정보주체의 권리)</TextTitle>
        <TextTitle>제 5 조 (법정대리인의 권리)</TextTitle>
        <TextTitle>제 6 조 (위치정보 이용¬ 제공사실 확인자료 보유근거 및 기간)</TextTitle>
        <TextTitle>제 7 조 (서비스의 변경 및 중지)</TextTitle>
        <TextTitle>제 8 조 (개인위치정보 제 3자 제공시 즉시 통보)</TextTitle>
        <TextTitle>제 9 조 (8세 이하의 아동 등의 보호의무자의 권리)</TextTitle>
        <TextTitle>제 10 조 (손해배상)</TextTitle>
        <TextTitle>제 11 조 (분쟁의 조정)</TextTitle>
        <TextTitle>제 12 조 (사업자 정보)</TextTitle>
        <TextTitle>부칙</TextTitle>
      </Box>
    </Container>
  )
};

export default LocationPage;
