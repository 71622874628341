import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';


function createData(name, specs) {
  return { name, specs};
}

const rows = [
  createData('제품명', 159),
  createData('본체 크기', 237),
  createData('본체 무게', 262),
  createData('전원', 'AC100V ~ 240V, 50Hz/60Hz 대응'),
  createData('전원 코드 길이', '100W (순간 피크 1500W)'),
  createData('색상', ''),
  createData('패키지 내용', '100W (순간 피크 1500W)'),
  createData('품질 보증 기간', '100W (순간 피크 1500W)'),
  createData('생산지', '대한민국'),
];

function SpecTable(props){
  return (
    <TableContainer component={Box}>
      <Table sx={{ width:'100%', position:'relative' }} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                }}>
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                }}
                align="left">{row.specs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SpecTable;
