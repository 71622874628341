import GridViewIcon from '@mui/icons-material/GridView';

import {
    CogOutlineIcon, PlusThickIcon,
    AutoFixIcon, BasketOutlineIcon,
    BellOutlineIcon, CurrencyUsdIcon,
    FinanceIcon, NecklaceIcon,
    PartyPopperIcon, StarIcon
} from '../../resources/icons';

const MainNavItems = [
    {
      key : 'dashboard',
      title : '대시보드',
      type : 'link',
      to : '/dashboard',
      icon : <GridViewIcon sx={{color:'white'}} color="white" />
    },
    {
      key : 'product_management',
      title : '상품 관리',
      type : 'accordion',
      icon : <NecklaceIcon color="white" />,
      children : [
        {
          key : 'product_list',
          title : '상품 리스트',
          type : 'link',
          to : '/do/product/recommend',
        },
        {
          key : 'product_add',
          title : '상품 등록',
          type : 'link',
          to : '/do/product/list',
        },
        {
          key : 'product_storage',
          title : '상품 재고 관리',
          type : 'link',
          to : '/do/product/match',
        },
      ]
    },
    {
      key : 'order_management',
      title : '주문 관리',
      type : 'accordion',
      icon : <BasketOutlineIcon color="white" />,
      children : [
        {
          key : 'order_total_list',
          title : '주문통합 리스트',
          type : 'link',
          to : '/host/list',
        },
        {
          key : 'delievery_list',
          title : '배송 리스트',
          type : 'link',
          to : '/crawler/list',
        },
        {
          key : 'waiting_deposit',
          title : '구매확정 리스트',
          type : 'link',
          to : '/crawler/list',
        },
        {
          key : 'change_or_refund',
          title : '교환 및 반품 관리',
          type : 'link',
          to : '/crawler/my',
        },
      ]
    },
    {
      key : 'customer_management',
      title : '고객 관리',
      type : 'accordion',
      icon : <AutoFixIcon color="white" />,
      children : [
        {
          key : 'message_module',
          title : '메세지 관리',
          type : 'link',
          to : '/product/my',
        },
        {
          key : 'review_management',
          title : '상품 후기 확인',
          type : 'link',
          to : '/product/list',
        },
        {
          key : 'black_customer',
          title : '블랙리스트 관리',
          type : 'link',
          to : '/storage',
        }
      ]
    },
    {
      key : 'promotion_management',
      title : '프로모션 관리',
      type : 'accordion',
      icon : <PartyPopperIcon color="white" />,
      children : [
        {
          key : 'coupon_management',
          title : '쿠폰 관리',
          type : 'link',
          to : '/tools/image_generator',
        },
        {
          key : 'coupon_send_management',
          title : '쿠폰 지급 관리',
          type : 'link',
          to : '/tools/image_generator',
        },
        {
          key : 'exhibition_management',
          title : '기획전 관리',
          type : 'link',
          to : '/tools/image_generator',
        }
      ]
    },
    {
      key : 'statistics',
      title : '통계',
      type : 'accordion',
      icon : <FinanceIcon color="white" />,
      children : [
        {
          key : 'product_statistics',
          title : '상품 통계',
          type : 'link',
          to : '/setting/brands',
        }
      ]
    },
    {
      key : 'paid_and_adjustment',
      title : '지급 및 정산',
      type : 'accordion',
      icon : <CurrencyUsdIcon color="white" />,
      children : [
        {
          key : 'adjustment_management',
          title : '정산 관리',
          type : 'link',
          to : '/setting/brands',
        },
        {
          key : 'unpaid_management',
          title : '미지급 분 관리',
          type : 'link',
          to : '/setting/brands',
        },
        {
          key : 'tax_bill_management',
          title : '세금 계산서 관리',
          type : 'link',
          to : '/setting/brands',
        }
      ]
    }
  ];

  export default MainNavItems;