import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import {
  Box,
  Container,
  Typography,
  Divider
} from '@mui/material';

import {
  TextTitle,
  TextSubtitle1,
  TextParagraph
} from '../../components/terms';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
} from '@mui/material/colors';

function PrivacyPage(props){
  return (
    <Container sx={{width:'100%', position:'relative'}}>
      <Box sx={{ my:2, padding:'48px 24px 108px'}}>
        <Typography
          sx={{
            fontFamily: "NotoSansKR",
            fontWeight:900,
            textAlign:'left',
            fontSize:'32px',
            mb:'24px',
            color:blueGrey[900]
          }}>
          개인정보처리방침
        </Typography>
        <Divider sx={{mb:'24px'}}/>
      </Box>
    </Container>
  );
};

export default PrivacyPage;
