import React, {useEffect, useState} from 'react';

import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import {
  Box,
  Button,
  IconButton,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Drawer,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import PhoneIphoneTwoToneIcon from '@mui/icons-material/PhoneIphoneTwoTone';

import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import Co2TwoToneIcon from '@mui/icons-material/Co2TwoTone';

import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';

import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';

import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import VolunteerActivismTwoToneIcon from '@mui/icons-material/VolunteerActivismTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

const NAVIGATION_LIST = [
  {
    key : "service",
    name: "서비스",
    children : [
      {
        key : "Daclair",
        name: "Daclair",
        icon : <PhoneIphoneTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/services/daclair"
      },
      {
        key : "ENV-REPORT",
        name: "ENV-REPORT",
        icon : <AssessmentTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/services/env-report"
      },
      {
        key : "ENV-AD",
        name: "ENV-AD",
        icon : <SummarizeTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/services/env-ad"
      },
      {
        key : "VCM",
        name: "탄소중립마켓(VCM)",
        icon : <Co2TwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/services/vcm"
      }
    ]
  },
  {
    key : "product",
    name: "제품",
    children : [
      {
        key : "tumbler-washer",
        name: "텀블러 세척기",
        icon : <SellTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/products/tumblerwasher"
      },
      {
        key : "cleaning-cart",
        name: "청소 카트",
        icon : <SellTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/products/cleaningcart"
      }
    ]
  },
  {
    key : "company",
    name: "회사 소개",
    children : [
      {
        key : "mission",
        name: "Mission",
        icon : <PublicTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/company/mission"
      },
      {
        key : "sustainability",
        name: "Sustainability",
        icon : <AllInclusiveTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/company/sustainability"
      },
      {
        key : "location",
        name: "회사 위치",
        icon : <LocationOnTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/company/location"
      }
    ]
  },
  {
    key : "customer",
    name: "고객지원",
    children : [
      {
        key : "notification",
        name: "공지사항",
        icon : <CampaignTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/notices"
      },
      {
        key : "campaign",
        name: "캠페인",
        icon : <VolunteerActivismTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/campaigns"
      },
      {
        key : "use-document",
        name: "사용메뉴얼",
        icon : <FindInPageTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/manuals"
      },
      {
        key : "contact",
        name: "문의하기",
        icon : <ContactSupportTwoToneIcon sx={{ fontSize:'20px', color : '#eeeeee' }}/>,
        url : "/company/contact"
      }
    ]
  }
];

function NavigationList(props){

  const navigate = useNavigate();
  const { onToggleDrawer } = props;

  return (
    <Box
      sx={{
        position:'relative',
        backgroundColor:blueGrey[900],
        width: 250,
        padding:'16px',
      }} role="presentation" onClick={onToggleDrawer(false)}>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          position:'relative',
          justifyContent: "center",
          alignItems: "center",
          width:'100%',
          py:'24px'
        }}>
        <img src="/assets/icons/logo_transparent.png" style={{height:'24px', position:'relative'}}/>
      </Stack>
      <List>
        {NAVIGATION_LIST.map((el, index) => (
          <>
            <Typography
              sx={{
                position:'relative',
                fontSize:'13px',
                color:'#bbbbbb',
                fontFamily:'NotoSansKR',
                fontWeight:900,
                mb:'6px',
                pl:'16px'
              }}>
            {el.name}
            </Typography>
            {el.children.map((_el, _index) => (
              <ListItem key={_el.key} disablePadding
                onClick={() => { navigate(_el.url); }}>
                <ListItemButton>
                  <ListItemIcon>
                    {_el.icon}
                  </ListItemIcon>
                  <ListItemText primary={
                    <Typography
                      sx={{
                        position:'relative',
                        fontSize:'14px',
                        color:'#eeeeee',
                        fontFamily:'NotoSansKR',
                        fontWeight:900,
                      }}>
                    {_el.name}
                    </Typography>
                  } />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ borderColor:'#666', my : '18px'}}/>
          </>
        ))}
      </List>
    </Box>
  );
}

export default NavigationList;
