import PropTypes from 'prop-types'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import useToken from './utils/useToken'
import { useNavigate } from 'react-router-dom';

import {
  SignedRoute,
  NonSignedRoute,
  PreviousRoute
} from './routes'

import {APIProvider} from '@vis.gl/react-google-maps';

const GOOGLE_MAP_API_KEY = "AIzaSyBWqp2CWXUzGoQJh3eEVAoaZC0icb0gsrw";

function App(props) {

  const { token, removeToken, setToken } = useToken();

  const handleRemoveToken = () => {
    removeToken();
  }

  return (
    <div className="App" >
      <APIProvider apiKey={GOOGLE_MAP_API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
        <BrowserRouter history={props.history}>
          {/*(props.__version === "V2") ? */((!token && token !== "" && token!== undefined) ?
          <NonSignedRoute setToken={setToken}/>
          : <SignedRoute { ...props } token={token} setToken={setToken} onRemoveToken={handleRemoveToken}/>)
          /*: <PreviousRoute { ...props } />*/}
        </BrowserRouter>
      </APIProvider>
    </div>
  );
}

App.propTypes = {
  history: PropTypes.object
}

export default App;
