import React from 'react';

import {
  Typography
} from '@mui/material';

function TextParagraph(props){
  const { children } = props;
  return (
    <Typography sx={{
      fontFamily: "NotoSansKR",
      fontSize: '16px',
      fontWeight: 500,
      color: '#222222',
      my: '6px',
      textAlign:'left',
      lineHeight:1.8
    }}>
      {children}
    </Typography>
  )
}

export default TextParagraph;
