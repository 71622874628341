import React from 'react';
import Icon from '@mdi/react';
import { mdiNecklace } from '@mdi/js';

export default function NecklackIcon(props){
    return (
        <Icon path={mdiNecklace }
            title="Necklace icon"
            size={1}
            color={props.color ? props.color : "black"}/>
    );
};