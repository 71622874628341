import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

import {
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip
} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import DiscountTwoToneIcon from '@mui/icons-material/DiscountTwoTone';

import SectionView from '../../components/home/SectionView';
import CarouselView from '../../components/home/CarouselView';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

function DashboardPage(props) {

  return (
    <React.Fragment>

      <Box sx={{width:'100%'}}>
        <SectionView>
          <CarouselView />
        </SectionView>
      </Box>

      <Container>
        <Box sx={{ my: 2, padding:'48px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:{xs:'24px',md:'42px'},
                  mb:'48px',
                  color:blueGrey[800]
                }}>
                사람과 환경을 우선으로 생각합니다
              </Typography>
            </Grid>
            <Grid item xs={12}sm={6} md={6}>
              <Paper
                className="da-box-shadow"
                elevation={2}
                sx={{position:'relative', width:'100%', paddingBottom:'60%', borderRadius:'16px', backgroundColor:lightBlue[900]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'15px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    제품 출시
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    Daclair 청소카트
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    편리하고 환경을 생각한 청소카트를 만나보세요
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}sm={6} md={6}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'60%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'15px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    출시 예정
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    텀블러 세척기
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    자원을 아끼기 위한 텀블러 세척기
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}sm={6} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    마켓플레이스
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    부속품 및 환경을 위한 용품을 구입하세요
                  </Typography>
                </Stack>
                <Chip
                  sx={{position:'absolute', top:'24px', left:'24px'}}
                  color="primary"
                  icon={<DiscountTwoToneIcon sx={{fontSize:'16px', m:'8px'}}/>} label="10% 할인"/>
              </Paper>
            </Grid>
            <Grid item xs={12}sm={6} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'15px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    서비스
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    다끌레어
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    환경 보호와 포인트 적립을 한번에
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}sm={6} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'15px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    도입 문의
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    기업을 위한 플랜
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    환경을 위해 기업을 위한 플랜을 준비했습니다
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2, padding:'48px 0px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item xs={12}sm={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'42px',
                  mb:'48px',
                  color:blueGrey[800]
                }}>
                다끌레어는 이렇게 발전해갑니다
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>

                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    전시회에 참가한 다끌레어
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    COEX, 2024. 05.
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'50%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'15px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    적용 사례
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    전국에 설치되어 있는 다끌레어를 찾아보세요
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    {moment().format('LL')} 기준 전국에 {12} 곳에 설치 되어 있습니다.
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'50%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Stack
                  sx={{position:'absolute', bottom:'24px', left:'24px'}}
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={.5}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'15px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    비젼
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'24px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    탄소 중립 마켓
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    다끌레어는 탄소 중립 마켓을 설립하여, 환경 보호에 앞장서는 캠페인을 진행중입니다
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:'100%', borderRadius:'16px', backgroundColor:lightBlue[600]}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>

                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    업체의 후기를 들어보세요
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    SRT, 2024. 08.
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={{ my: 2, padding:'108px 0px 108px', backgroundColor:lightBlue[700] }}>
        <Stack spacing={2}
          direction="column"
          justifyContent="center"
          sx={{ width:'100%'}}
          alignItems="center">

          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:900,
              textAlign:'center',
              fontSize:'32px',
              mb:'48px !important',
              color:'#ffffff'
            }}>
            다끌레어에 대해 더 많은 정보를 알고 싶다면
          </Typography>


          <Stack
            direction="column"
            className="da-button-arrow"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{padding:'0px 0px 0px 0px', width:'100%', maxWidth:'300px', boxSizing:'border-box'}}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:700,
                fontSize:'22px',
                textAlign:'left',
                color:'#ddd'
              }}>
              지금 바로 문의하세요
            </Typography>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:500,
                fontSize:'16px',
                textAlign:'left',
                color:'#ddd'
              }}>
              문의하기
            </Typography>
          </Stack>

        </Stack>
      </Box>
    </React.Fragment>
  );
}

export default DashboardPage;
