import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,
  Tabs,
  Tab,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import BgVideo from '../../resources/videos/daclair_video.mp4'
import SpecTable from '../../components/product/SpecTable';
import FirstEnviromentContainer from '../../components/product/FirstEnviromentContainer';
import SecondEnviromentContainer from '../../components/product/SecondEnviromentContainer';
import ThirdEnviromentContainer from '../../components/product/ThirdEnviromentContainer';

import moment from 'moment';
import 'moment/locale/ko';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TumblerWasherPage(props){

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{position:'relative', width:'100%', paddingBottom:'calc(100vh - 180px)', backgroundColor:'#222222'}}>
        <video autoPlay loop muted className="da-background-video"><source src={BgVideo} type="video/mp4" /> </video>
        <Stack
          direction="column"
          spacing={3}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            position:'absolute', top:0,left:0,right:0,bottom:0,width:'100%',height:'100%'
          }}>
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:900,
              textAlign:'center',
              fontSize:'42px',
              color:'#ffffff'
            }}>
            탄소 중립의 새로운 시작
          </Typography>
          <img
            style={{width:'180px', position:'relative'}}
            src="/assets/icons/daclair_tb_logo_white_under_1028.png"/>
        </Stack>
      </Box>
      <Container>
        <Box sx={{ my: 2, padding:'48px 24px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'42px',
                  mb:'48px',
                  color:blueGrey[800]
                }}>
                환경을 항상 생각하며 만듭니다
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  fontSize:'16px',
                  textAlign:'center',
                  color:blue[500],
                  mb:'8px'
                }}>
                친환경 텀블러 세척기
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  fontSize:'28px',
                  textAlign:'center',
                  mb:'24px',
                  color:blueGrey[900]
                }}>
                탄소 중립의 시작은 Daclair 텀블러 세척기로
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                  fontSize:'18px',
                  textAlign:'center',
                  lineHeight:1.8,
                  color:blueGrey[900]
                }}>
                텀블러 사용은 일회용품을 대체하는 탄소 중립의 첫 걸음입니다.
                <br/>그러나 텀블러를 사용하는 것은 불편한 세척을 항상 동반합니다.
                <br/>Daclair 텀블러 세척기는 강한 세척력으로 이러한 불편함을 빠르게 해결하며
                <br/>물 및 에너지 절약을 통해 탄소 중립을 실천할 수 있습니다
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{position:'relative', width:'100%', paddingBottom:'60%'}}>
                <img
                  style={{
                    position:'absolute',
                    width:'100%', height:'100%',
                    top:'0px', left:'0px', right:'0px', bottom:'0px',
                    objectFit:'cover'
                  }}
                  src="/assets/images/daclair_image1.png" />
                <Box sx={{
                  position:'absolute', bottom:'0px', left:'0px', width:'100%', height:'30%',
                  background:'linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1))'}}>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2, padding:'48px 24px 108px' }}>
          <Grid container spacing={3}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'36px',
                  mb:'24px',
                  color:blueGrey[800]
                }}>
                최적의 텀블러 세척기
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                  textAlign:'center',
                  fontSize:'24px',
                  mb:'48px',
                  color:'#222222'
                }}>
                다끌레어 텀블러 세척기는 사용자의 편의를 위해 다양한 기능을 탑재하고 있습니다.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="da-hover_box">
              <Box sx={{position:'relative', width:'100%', paddingBottom:'80%', mb:'36px'}}>
                <img
                  style={{
                    borderTopLeftRadius:'16px',
                    borderTopRightRadius:'16px',
                    objectFit:'cover',
                    position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%'}}
                  src="/assets/images/daclair_des2.png" />
              </Box>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height:'180px'
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'20px',
                    textAlign:'left',
                    color:blueGrey[700]
                  }}>
                  안전한 컵 세척
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'16px',
                    textAlign:'left',
                    color:'#444444'
                  }}>
                  6개의 다리는 다양한 컵과 텀블러를 안전하게 세척할 수 있는 구조로,
                  강한 수압으로도 쉽게 넘어지지 않습니다.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="da-hover_box">
              <Box sx={{position:'relative', width:'100%', paddingBottom:'80%', mb:'36px'}}>
                <img
                  style={{
                    borderTopLeftRadius:'16px',
                    borderTopRightRadius:'16px',
                    objectFit:'cover',
                    position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%'}}
                  src="/assets/images/daclair_des3.png" />
              </Box>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height:'180px'
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'20px',
                    textAlign:'left',
                    color:blueGrey[700]
                  }}>
                  분리 세척이 가능
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'16px',
                    textAlign:'left',
                    color:'#444444'
                  }}>
                  스탠인리스로 상단을 구성하였으며,
                  상단을 쉽게 분리할 수 있어서 세척에 용이합니다.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="da-hover_box">
              <Box sx={{position:'relative', width:'100%', paddingBottom:'80%', mb:'36px'}}>
                <img
                  style={{
                    borderTopLeftRadius:'16px',
                    borderTopRightRadius:'16px',
                    objectFit:'cover',
                    position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%'}}
                  src="/assets/images/daclair_des4.png" />
              </Box>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height:'180px'
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'20px',
                    textAlign:'left',
                    color:blueGrey[700]
                  }}>
                  위생 도기
                  <sup>*</sup> 로 제작
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'16px',
                    textAlign:'left',
                    color:'#444444'
                  }}>
                  위생 도기로 제품을 구성하여, 외관과 위를 모두 물 세척을 할 수 있습니다.
                  이를 통해 제품을 위생적으로 관리할 수 있습니다.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:300,
                    fontSize:'13px',
                    textAlign:'left',
                    color:'#555555'
                  }}>
                  * 테이블형 및 제작형은 상단만 위생도기로 구성됩니다.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="da-hover_box">
              <Box sx={{position:'relative', width:'100%', paddingBottom:'80%', mb:'36px'}}>
                <img
                  style={{
                    borderTopLeftRadius:'16px',
                    borderTopRightRadius:'16px',
                    objectFit:'cover',
                    position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%'}}
                  src="/assets/images/daclair_des5.png" />
              </Box>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height:'180px'
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'20px',
                    textAlign:'left',
                    color:blueGrey[700]
                  }}>
                  UVC 소독효과
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'16px',
                    textAlign:'left',
                    color:'#444444'
                  }}>
                  3개의 UVC LED (275nm) 가 세척과 동시에 내부의 소독을 도와줍니다.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="da-hover_box">
              <Box sx={{position:'relative', width:'100%', paddingBottom:'80%', mb:'36px'}}>
                <img
                  style={{
                    borderTopLeftRadius:'16px',
                    borderTopRightRadius:'16px',
                    objectFit:'cover',
                    position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%'}}
                  src="/assets/images/daclair_des6.png" />
              </Box>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height:'180px'
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'20px',
                    textAlign:'left',
                    color:blueGrey[700]
                  }}>
                  QR code 및 바코드 리더
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'16px',
                    textAlign:'left',
                    color:'#444444'
                  }}>
                  QR 코드 리더기를 통해 세척하는 텀블러 및 유저를 특정하여 여러 서비스<sup>*</sup>에 이용됩니다.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:300,
                    fontSize:'13px',
                    textAlign:'left',
                    color:'#555555'
                  }}>
                  * Daclair 서비스에 포인트 적립 용도로 사용되어집니다
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="da-hover_box">
              <Box sx={{position:'relative', width:'100%', paddingBottom:'80%', mb:'36px'}}>
                <img
                  style={{
                    borderTopLeftRadius:'16px',
                    borderTopRightRadius:'16px',
                    objectFit:'cover',
                    position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%'}}
                  src="/assets/images/daclair_display.png" />
              </Box>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height:'180px'
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'20px',
                    textAlign:'left',
                    color:blueGrey[700]
                  }}>
                  최상단 터치 버튼 및 LED 패널
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'16px',
                    textAlign:'left',
                    color:'#444444'
                  }}>
                  여러 LED와 터치 버튼을 통해서 텀블러 세척기가 가지고 있는 다양한 기능들을 표기하고 구성합니다.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2, padding:'48px 24px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:'32px',
                  mb:'12px',
                  color:blueGrey[800]
                }}>
                나에게 맞는 텀블러 세척기 찾기
              </Typography>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:500,
                  textAlign:'center',
                  fontSize:'24px',
                  mb:'48px',
                  color:blueGrey[500]
                }}>
                상황과 환경에 따라 텀블러 세척기를 선택할 수 있습니다
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:{md:'120%', sm:'150%', xs:'120%'}, borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>
                  <img src="/assets/images/daclair_option1.png"
                    style={{position:'absolute',
                      borderTopLeftRadius:'16px',
                      borderTopRightRadius:'16px',
                      objectFit:'cover',
                      top:'0px', left:'0px', bottom:'0px', right:'0px',
                      width:'100%', height:'100%'}}/>
                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'20px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    독립형
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'14px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    단독으로 동작하는 기기입니다
                    <br/> 사무실 및 공용시설에 적합합니다
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:{md:'120%', sm:'150%', xs:'120%'}, borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>
                  <img src="/assets/images/daclair_option2.png"
                    style={{position:'absolute',
                      borderTopLeftRadius:'16px',
                      borderTopRightRadius:'16px',
                      objectFit:'cover',
                      top:'0px', left:'0px', bottom:'0px', right:'0px',
                      width:'100%', height:'100%'}}/>
                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'20px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    테이블형
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'14px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    원하는 위치에 설치가능합니다
                    <br/> 인테리어 맞춤으로 주변과 맞추어 설치가 가능합니다
                    <br/> 카페와 같은 곳에 추천합니다
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className="da-box-shadow"
                elevation={2} sx={{position:'relative', width:'100%', paddingBottom:{md:'120%', sm:'150%', xs:'120%'}, borderRadius:'16px', backgroundColor:lightBlue[600], boxSizing:'border-box'}}>
                <Box
                  sx={{position:'absolute', top:'0px', left:'0px', right:'0px', width:'100%', height:'60%', backgroundColor:'#222222', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}}>
                  <img src="/assets/images/daclair_option3.png"
                    style={{position:'absolute',
                      borderTopLeftRadius:'16px',
                      borderTopRightRadius:'16px',
                      objectFit:'cover',
                      top:'0px', left:'0px', bottom:'0px', right:'0px',
                      width:'100%', height:'100%'}}/>
                </Box>
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{position:'absolute', top:'calc(60% + 24px)', left:'0px', right:'0px', width:'calc(100% - 24px)', padding:'0px 0px 24px 24px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:900,
                      fontSize:'20px',
                      textAlign:'left',
                      color:'#ffffff'
                    }}>
                    멀티 테이블형
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'14px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    원하는 위치에 설치가능합니다
                    <br/> 인테리어 맞춤으로 주변과 맞추어 설치가 가능합니다
                    <br/> 테이블형의 확장으로 유동인구가 많은 곳에 용이합니다.
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <FirstEnviromentContainer />
      <SecondEnviromentContainer />
      <ThirdEnviromentContainer />
      <Box sx={{position:'relative', width:'100%', height:'64px'}}>
      </Box>
      <Box sx={{position:'relative', width:'100%'}}>
        <Container sx={{width:'100%', position:'relative'}}>
          <Typography
            sx={{
              fontFamily: "NotoSansKR",
              fontWeight:900,
              textAlign:'center',
              fontSize:'28px',
              mb:'32px',
              color:blueGrey[600]
            }}>
            텀블러 세척기에 대해 더 알아보세요
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="사양" {...a11yProps(0)} sx={{fontFamily: "NotoSansKR", fontWeight:900}} />
            <Tab label="악세서리" {...a11yProps(1)} sx={{fontFamily: "NotoSansKR", fontWeight:900}} />
            <Tab label="부가사항" {...a11yProps(2)} sx={{fontFamily: "NotoSansKR", fontWeight:900}} />
          </Tabs>

          <CustomTabPanel value={tabValue} index={0} sx={{py:'36px'}}>
            <Typography
              sx={{
                fontFamily: "NotoSansKR",
                fontWeight:900,
                fontSize:'32px',
                textAlign:'left',
                color:'#222222',
                my:'24px'
              }}>
              제품 사양
            </Typography>
            <SpecTable />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>

          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>

          </CustomTabPanel>

        </Container>
      </Box>
      <Box
        sx={{width:'100%', backgroundColor:blueGrey[800]}}>
        <Box sx={{ my: 2, padding:'72px 24px 108px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            spacing={2}
            alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'center',
                  fontSize:{md : '36px', sm:'30px', xs:'26px'},
                  mb:'72px',
                  color:'#ffffff'
                }}>
                처음 사용하시거나, 사용 방법을 잘 모르시나요?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={2}
                direction="column"
                justifyContent="center"
                sx={{ width:'100%', mb:'24px'}}
                alignItems="center">
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{padding:'0px 0px 0px 0px', width:'100%', maxWidth:'300px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    사용 메뉴얼을 확인해보세요
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    Daclair 사용 메뉴얼
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={2}
                direction="column"
                justifyContent="center"
                sx={{ width:'100%', mb:'24px'}}
                alignItems="center">
                <Stack
                  direction="column"
                  className="da-button-arrow"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{padding:'0px 0px 0px 0px', width:'100%', maxWidth:'300px', boxSizing:'border-box'}}>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:700,
                      fontSize:'22px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    고객센터에 문의하세요
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "NotoSansKR",
                      fontWeight:500,
                      fontSize:'16px',
                      textAlign:'left',
                      color:'#ddd'
                    }}>
                    문의하기
                  </Typography>
                </Stack>
              </Stack>
            </Grid>


          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default TumblerWasherPage;
