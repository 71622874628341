import React from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,
  TextField,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Input,
  OutlinedInput
} from '@mui/material';

import { IMaskInput } from 'react-imask';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import moment from 'moment';
import 'moment/locale/ko';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function ContactUsPage(props){

  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    textmask: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const noticeBackAction = () => {
    navigate(-1);
  };

  return (
    <>
      <Container>
        <Box sx={{ my: 2, padding:'48px 0px 12px' }}>
          <Grid container spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width:'100%'}}
            alignItems="center">
            <Grid item md={12}>
              <Typography
                sx={{
                  fontFamily: "NotoSansKR",
                  fontWeight:900,
                  textAlign:'left',
                  fontSize:'32px',
                  mb:'24px',
                  color:blueGrey[800]
                }}>
                문의하기
              </Typography>
              <Divider />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2, padding:'24px 0px 108px' }}>
          <Grid spacing={2}
            container
            direction="column"
            sx={{
             justifyContent: "flex-start",
             alignItems: "flex-start",
            }}
            sx={{ width:'100%'}}>
            <Grid item md={12}>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}>
                <TextField
                  required
                  id="cs-name"
                  fullWidth
                  type="text"
                  label="성함"/>
                <TextField
                  required
                  fullWidth
                  id="cs-rank"
                  type="text"
                  label="직함"/>
                <TextField
                  required
                  fullWidth
                  id="cs-email"
                  type="email"
                  label="이메일 주소"/>
                <FormControl variant="outlined" sx={{width:'100%'}}>
                  <InputLabel htmlFor="cs-number">전화번호</InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    sx={{width:'100%'}}
                    value={values.textmask}
                    onChange={handleChange}
                    name="cs-number"
                    id="cs-number"
                    inputComponent={TextMaskCustom}
                  />
                </FormControl>
                <TextField
                  required
                  fullWidth
                  id="cs-company"
                  type="text"
                  label="기관 / 회사명"/>
                <TextField
                  required
                  fullWidth
                  id="cs-nation"
                  type="text"
                  label="국가"/>
                <TextField
                  required
                  fullWidth
                  id="cs-description"
                  label="문의 사항을 남겨주세요"
                  multiline
                  rows={3} />
                <FormControlLabel required control={<Checkbox />} label="개인정보처리방침을 확인하였으며, 이에 동의합니다." />
                <FormControlLabel required control={<Checkbox />} label="[선택] 다끌레어의 뉴스레터 수신에 동의합니다." />
                <Button variant="outlined"
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    color:'#ffffff',
                    backgroundColor:lightBlue[500],
                    border:'none',
                    fontSize:'18px',
                    mt:'12px',
                    width:'100%', height:'60px'}}>보내기</Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default ContactUsPage;
