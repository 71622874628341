import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Chip,
  Container,
  Typography,
  Stack,

} from '@mui/material';

import {
  blue,
  lightBlue,
  grey,
  blueGrey,
  teal
} from '@mui/material/colors';

import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

function ThirdEnviromentContainer(props){
  return (
    <Box
      sx={{width:'100%', height:'50vh', position:'relative'}}>
      <Box sx={{position:'relative', width:'100%', height:'100%'}}>
        <img
          style={{
            position:'absolute',
            top:'0px', bottom:'0px', left:'0px', right:'0px', width:'100%', height:'100%',
            objectFit:'cover', filter:'brightness(40%)'
          }}
          src="/assets/images/env_back3.jpg" />
        <Box sx={{
          position:'absolute',
          top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
          background:'linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1))'
        }}>
        <Container sx={{position:'relative', width:'100%' , height:'100%'}}>
          <Grid
            container
            direction="row"
            sx={{
              position:'relative', width:'100%', height:'100%',
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid item xs={0} sm={0} md={6}>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:700,
                    fontSize:'16px',
                    textAlign:'left',
                    width:'100%',
                    color:lightBlue[300]
                  }}>
                  관리 지원
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:900,
                    fontSize:'28px',
                    textAlign:'left',
                    width:'100%',
                    color:blueGrey[100]
                  }}>
                  전용 관리자 어플리케이션
                </Typography>
                <Box sx={{width:'100%', height:'24px'}}></Box>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'18px',
                    textAlign:'left',
                    width:'100%',
                    color:'#eeeeee'
                  }}>
                  다끌레어 텀블러 세척기는 관리 가능한 전용 어플리케이션이 있습니다.
                  <br/> 어플리케이션을 통해 여러 설정들을 할 수 있으며, 실시간 모니터링 및 기타 관리 서비스를 받아보세요

                </Typography>
                <Box sx={{width:'100%', height:'24px'}}></Box>
                <Typography
                  sx={{
                    fontFamily: "NotoSansKR",
                    fontWeight:500,
                    fontSize:'18px',
                    textAlign:'left',
                    width:'100%',
                    color:'#eeeeee'
                  }}>
                  <Button variant="outlined" sx={{border:'1px solid #ffffff', color:'#ffffff'}}
                    endIcon={<ArrowForwardTwoToneIcon sx={{color:'#ffffff'}}/>}>
                  어플리케이션 확인
                  </Button>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default ThirdEnviromentContainer;
