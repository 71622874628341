import React, { useRef, useState } from 'react';

import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Stack,
  Divider,
  IconButton
} from '@mui/material';

function ThirdEventView(){
  return (
    <Box>
    </Box>
  );
}

export default ThirdEventView;
