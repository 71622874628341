import React from 'react';

import {
  Typography
} from '@mui/material';

function TextSubtitle1(props){
  const { children } = props;
  return (
    <Typography sx={{
      fontFamily: "NotoSansKR",
      fontSize: '20px',
      fontWeight: 700,
      color: '#000000',
      lineHeight:2,
      my: '12px',
      textAlign:'left'
    }}>
      {children}
    </Typography>
  )
}

export default TextSubtitle1;
