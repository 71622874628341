import React from 'react';
import Icon from '@mdi/react';
import { mdiFinance } from '@mdi/js';

export default function FinanceIcon(props){
    return (
        <Icon path={ mdiFinance }
            title="finance icon"
            size={1}
            color={props.color ? props.color : "black"}/>
    );
};