import React from 'react';

import {
  Typography
} from '@mui/material';

function TextTitle(props){
  const { children } = props;
  return (
    <Typography sx={{
      fontFamily: "NotoSansKR",
      fontSize: '26px',
      fontWeight: 900,
      color: '#000000',
      my: '24px',
      textAlign:'left'
    }}>
      {children}
    </Typography>
  )
}

export default TextTitle;
